//
// Input Group
//

// BEGIN: Hide Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
// END: Hide Arrows From Input Number

// Form control
.input-group {
  &.input-group-solid {
    @include border-radius($input-border-radius);

    &.input-group-sm {
      @include border-radius($input-border-radius-sm);
    }

    &.input-group-lg {
      @include border-radius($input-border-radius-lg);
    }

    .input-group-text {
      background-color: $input-solid-bg;
      border-color: $input-solid-bg;

      & + .form-control {
        border-left-color: $input-border-color;
      }
    }

    .form-control {
      background-color: $input-solid-bg;
      border-color: $input-solid-bg;

      & + .input-group-text {
        border-left-color: $input-border-color;
      }
    }
  }
}
