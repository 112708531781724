.dashboard {
  & .h-40 {
    height: 40px;
  }

  & .h-210 {
    height: 210px;
  }

  &__wallet {
    border: 2px solid $primary;

    &.red-border {
      border: 2px solid $danger;
    }

    & input.form-check-input:checked {
      background-color: $primary;
      border-color: $primary;
    }

    &__add-fund {
      & > button {
        color: $primary;
        border-color: $primary;
      }
    }
  }

  &__activity {
    &__color-green {
      color: $primary;
    }
    &__color-purple {
      color: $info;
    }
  }

  &__shipments {
    &__checkBox {
      height: 16px;
      width: 16px;
    }

    &__bg-progress {
      &--0 {
        background-color: #115e59;
      }
      &--1 {
        background-color: #0f766e;
      }
      &--2 {
        background-color: #0d9488;
      }
      &--3 {
        background-color: #14bba6;
      }
      &--4 {
        background-color: #2dd4bf;
      }
    }

    & input.form-check-input:checked {
      background-color: $primary;
      border-color: $primary;
    }

    &__total,
    &__delivered,
    &__delivered-percent {
      display: flex;
      flex-direction: column;
      height: 100px;
      justify-content: space-between;
      padding: 8px;
    }

    &__total {
      border: 4px solid $gray-400;
      border-radius: 16px;
    }

    &__delivered {
      border: 4px solid $gray-500;
      border-radius: 16px;
    }

    &__delivered-percent {
      border: 4px solid $gray-600;
      border-radius: 16px;
    }
  }
}
