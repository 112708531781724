$sizes: ();
@for $i from -30 through 30 {
  $sizes: append($sizes, if($i < 0, 'n#{abs($i)}', $i));
}

@each $index in $sizes {
  $value: if(
    type-of($index) == 'string',
    calc(-1 * #{str-slice($index, 2)} * 0.25rem),
    $index * 0.25rem
  );

  .right-#{$index} {
    right: $value !important;
  }
}
