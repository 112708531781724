//
// Landing page
//

// Variables

$gradient-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.05) -9.09%, #bee5e2 63.41%);
$gradient-bg2: linear-gradient(
  180deg,
  rgba(187, 221, 219, 0) 0%,
  rgba(187, 221, 219, 0.1) 15.5%,
  rgba(187, 221, 219, 0.35) 44%,
  rgba(187, 221, 219, 0.53) 73%,
  rgba(187, 221, 219, 0.94) 100%
);
$light-blue: #bfe2df;

header,
.landing-page {
  a.btn {
    &.btn-primary.hover-orange {
      &:hover {
        color: #fff !important;
        background-color: #ffa25f !important;
      }
    }
  }
}

.landing-page {
  font-family: 'Poppins', sans-serif;

  .light-blue-bg {
    background: $light-blue;
  }

  .gradient-bg {
    background: $gradient-bg;
  }

  .gradient-bg2 {
    background: $gradient-bg2;
  }

  .w-fit-content {
    width: fit-content;
  }

  .mb-12px {
    margin-bottom: 12px;
  }

  .overflow-x-hidden {
    overflow-x: hidden;
  }

  img {
    max-width: 100%;
  }

  .mobile-only {
    @media (min-width: 1024px) {
      display: none !important;
    }
  }

  .desktop-only {
    display: none !important;

    @media (min-width: 1024px) {
      display: block !important;
    }
  }

  h1,
  h2,
  h3 {
    font-family: 'Raleway', sans-serif;
  }

  a.btn {
    font-family: 'Pretendard', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    text-transform: capitalize;
    padding: 1rem;

    @media (min-width: 1024px) {
      font-size: 24px;
      padding: 1.5rem;
    }

    &.custom-rocket-btn {
      background: #fff;
      border-color: #64748b !important;
      color: #64748b;

      @media (min-width: 1024px) {
        padding: 20px 30px;
      }

      &:hover {
        background: #27364b;
        color: #14b8a6;
      }
    }

    &.gradient {
      border-radius: 40px;
      backdrop-filter: blur(10px);
      background-size: 300% 100%;
      background: linear-gradient(
          120deg,
          rgba(52, 191, 176, 0.8) 0%,
          rgba(151, 71, 255, 0.8) 33%,
          rgba(151, 71, 255, 0.8) 66%,
          rgba(52, 191, 176, 0.8) 100%
        )
        0 0;
      transition: background-position 0.25s;

      &:hover {
        background-position: 100% 0;
      }
    }
  }

  .pretendard {
    font-family: 'Pretendard', sans-serif;
  }

  h2.custom-h2 {
    color: #27364b;
    font-size: 40px;

    @media (min-width: 1024px) {
      font-size: 62px;
    }

    &.light {
      color: #94a3b8;
    }

    span {
      color: #14b8a6;
    }
  }

  h3.custom-h3 {
    font-size: 28px;
    text-align: center;

    @media (min-width: 1024px) {
      font-size: 30px;
      text-align: left;
    }
  }

  // Section specific styles
  section.landing-hero {
    margin-top: 75.45px;
    padding: 5rem 0;

    @media (min-width: 1024px) {
      padding: 7rem 0;
    }

    h1 {
      font-size: 40px;
      line-height: 1;
      font-weight: 800 !important;

      @media (min-width: 1024px) {
        font-size: 62px;
      }
    }

    p.h1-sub-copy {
      color: #1e2a3b;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }

    p.mw-1116px {
      max-width: 1116px;
    }

    .mb-64px {
      margin-bottom: 64px;
    }
  }

  .sticky-device-container {
    position: relative;
    padding: 0 36px;

    #sticky-device {
      display: block;
      position: sticky;
      top: 25%;
      left: 50%;
      height: 50vh;

      @media (min-width: 600px) and (max-height: 599px) {
        top: 20px;
        height: 100vh;
      }

      @media (min-width: 768px) and (min-height: 600px) {
        top: 50px;
        height: 75vh;
      }

      @media (min-width: 768px) and (min-height: 1024px) {
        top: 100px;
        height: 50vh;
      }

      @media (min-width: 1024px) {
        top: 0;
        height: 103vh;
      }

      @media (min-width: 1024px) and (min-height: 1024px) {
        top: 10vh;
        height: 80vh;
      }

      > .device {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  // Video in Device Frame: Laptop and Tablet images
  .device-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .device {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      &.sticky-device {
        width: 300px;
        height: 232px;

        @media (min-width: 600px) and (max-height: 599px) {
          width: 300px;
          height: 232px;
        }

        @media (min-width: 768px) and (max-height: 599px) {
          width: 368px;
          height: 278px;
        }

        @media (min-width: 768px) and (min-height: 600px) {
          width: 600px;
          height: 464px;
        }

        @media (min-width: 768px) and (min-height: 1080px) {
          width: 529px;
          height: 682px;
        }

        @media (min-width: 1024px) {
          width: 110vh;
          height: 68vh;
        }

        @media (min-width: 1024px) and (min-height: 790px) {
          width: 870px;
          height: 538px;
        }

        @media (min-width: 1024px) and (min-height: 1080px) {
          width: 980px;
          height: 610px;
        }

        @media (min-width: 1300px) and (min-height: 1080px) {
          width: 1120px;
          height: 682px;
        }

        .deviceBorder {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          overflow: hidden;
          z-index: 100;
          width: 300px;
          height: 87%;

          @media (min-width: 600px) {
            width: 529px;
            height: 87%;
          }

          @media (min-width: 768px) {
            width: 600px;
            height: 87%;
          }

          @media (min-width: 768px) and (min-height: 1080px) {
            width: 529px;
            height: 50%;
          }

          @media (min-width: 1024px) {
            width: 1120px;
            height: auto;
          }
        }

        video {
          position: absolute;
          display: block;
          top: 51%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 92%;
          z-index: 50;

          @media (min-width: 768px) {
            display: block;
            top: 50%;
            width: 93%;
          }

          @media (min-width: 768px) and (min-height: 1080px) {
            display: block;
            top: 51%;
            width: 93%;
          }

          @media (min-width: 1024px) {
            width: 82.5%;
            top: 45.5%;
          }
        }
      }

      &.tablet {
        width: calc(100vw - 40px);
        height: 72vw;

        @media (min-width: 768px) {
          width: 529px;
          height: 406px;
        }

        @media (min-width: 1024px) {
          width: 846px;
          height: 658px;
          transform: translateX(10%);
        }

        @media (min-width: 1100px) {
          transform: translateX(27%);
        }

        @media (min-width: 1400px) {
          transform: translateX(37%);
        }

        .deviceBorder {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1120px;
          height: auto;
          overflow: hidden;
          z-index: 100;
        }

        video {
          position: absolute;
          display: block;
          top: 51%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 92%;
          z-index: 50;

          @media (min-width: 960px) {
            top: 51%;
          }
        }
      }
    }
  }

  .scrolling-text {
    font-family: 'Raleway', sans-serif;
    font-size: 70px;
    line-height: 1;
    font-weight: 800;
    display: block;
    width: 100%;

    span {
      font-size: 35px;
      line-height: 1;
      color: #16baa8;
    }

    @media (min-width: 1024px) {
      font-size: 130px;
      line-height: 80px;

      span {
        font-size: 70px;
        line-height: 80px;
      }
    }

    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    &.dark {
      color: #4e7c78;
      -webkit-text-stroke-color: #fff;
      -webkit-text-stroke-width: 1px;

      span {
        -webkit-text-stroke-width: 0;
      }
    }

    &.light {
      color: #c8e9e6;
    }

    &.text-darkTeal2 {
      color: #0d9488;
    }

    &.first {
      margin-bottom: 0;
      position: absolute;
      top: 170px;
      left: 0;

      @media (min-width: 1024px) {
        margin-bottom: 0;
      }
    }

    &.second {
      margin-bottom: 335px;

      @media (min-width: 1024px) {
        margin-top: -400px;
        margin-bottom: 670px;
      }
    }

    &.third {
      margin-bottom: 300px;

      @media (min-width: 1024px) {
        margin-bottom: 1115px;
      }
    }

    &.last {
      font-size: 28px;
      line-height: 1;
      text-transform: capitalize;

      @media (min-width: 1024px) {
        font-size: 56px;
        line-height: 80px;
      }
    }
  }

  section.features {
    padding: 5rem 0;

    @media (min-width: 1024px) {
      padding: 250px 0 140px;
    }
  }

  // Flourish Card Animation
  ul.animated-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-x: hidden;
    margin-bottom: 0;
    min-height: 365px;

    @media (min-width: 768px) {
      min-height: 615px;
    }

    @media (min-width: 1550px) {
      min-height: 475px;
    }

    .card {
      position: absolute;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      background: rgba(200, 236, 232, 0.4);
      backdrop-filter: blur(20px);
      display: block;
      padding: 15px 11px;
      width: 164px;
      height: 185px;
      border-radius: 10px;

      @media (min-width: 768px) {
        border-radius: 24px;
        padding: 40px 30px;
        width: 260px;
        height: 306px;
      }

      @media (min-width: 1550px) {
        padding: 60px 50px;
        width: 354px;
        height: 420px;
      }

      p {
        font-family: 'Raleway', sans-serif;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0 0 12px;
        text-align: left;
        font-weight: 800;

        &.eyebrow {
          color: #1e2a3b;
          line-height: normal;
          margin-bottom: 0;
          font-size: 10px;

          @media (min-width: 1024px) {
            font-size: 12px;
          }

          @media (min-width: 1550px) {
            font-size: 16px;
          }
        }

        &.title {
          color: #0f1a2a;
          text-transform: capitalize;
          overflow-wrap: normal;
          font-size: 16px;
          line-height: 1.5;

          @media (min-width: 768px) {
            font-size: 40px;
            line-height: 80px;
          }

          @media (min-width: 1550px) {
            font-size: 56px;
          }
        }
      }

      img {
        display: block;
        width: 77px;
        height: 77px;
        margin: auto;

        @media (min-width: 1024px) {
          width: 120px;
          height: 120px;
        }

        @media (min-width: 1550px) {
          width: 200px;
          height: 200px;
        }
      }

      &.one {
        z-index: 1;
        left: calc(50% - 23px);
        transform: translateX(-50%) rotate(-20deg);

        transition: all 0.25s linear;

        p {
          &.title {
            @media (min-width: 1550px) {
              font-size: 45px !important;
            }
          }
        }
      }

      &.two {
        z-index: 2;
        left: calc(50% - 11.5px);
        transform: translateX(-50%) rotate(-10deg);

        transition: all 0.25s linear;
      }

      &.three {
        z-index: 3;
        left: 50%;
        transform: translateX(-50%);

        transition: all 0.25s linear;
      }

      &.four {
        z-index: 4;
        left: calc(50% + 11.5px);
        transform: translateX(-50%) rotate(9deg);

        transition: all 0.25s linear;
      }

      &.five {
        z-index: 5;
        left: calc(50% + 23px);
        transform: translateX(-50%) rotate(18deg);

        transition: all 0.25s linear;
      }

      @media (min-width: 768px) {
        &.one {
          z-index: 1;
          left: calc(50% - 98px);
          transform: translateX(-50%) translateY(30px) rotate(-10deg);

          transition: all 0.25s linear;

          p {
            &.title {
              @media (min-width: 1550px) {
                font-size: 45px !important;
              }
            }
          }
        }

        &.two {
          z-index: 2;
          left: calc(50% - 58px);
          transform: translateX(-50%) translateY(15px) rotate(-5deg);

          transition: all 0.25s linear;
        }

        &.three {
          z-index: 3;
          left: calc(50% - 4px);
          transform: translateX(-50%) translateY(-1px);

          transition: all 0.25s linear;
        }

        &.four {
          z-index: 4;
          left: calc(50% + 38px);
          transform: translateX(-50%) translateY(-2px) rotate(5deg);

          transition: all 0.25s linear;
        }

        &.five {
          z-index: 5;
          left: calc(50% + 96px);
          transform: translateX(-50%) translateY(0px) rotate(10deg);

          transition: all 0.25s linear;
        }
      }

      @media (min-width: 1550px) {
        &.one {
          transition: all 0.25s linear;
        }

        &.two {
          transition: all 0.25s linear;
        }

        &.three {
          transition: all 0.25s linear;
        }

        &.four {
          transition: all 0.25s linear;
        }

        &.five {
          transition: all 0.25s linear;
        }
      }
    }

    &.flourish {
      .card {
        &.one {
          left: calc(50% - 80px);
          transform: rotate(-11.971deg) translateX(-50%) translateY(-16px);

          animation: 0.25s ease-in-out 0.25s slightBounceMobile1;

          @keyframes slightBounceMobile1 {
            50% {
              left: calc(50% - 90px);
              transform: rotate(-11.971deg) translateX(-50%) translateY(-16px);
            }

            100% {
              left: calc(50% - 80px);
              transform: rotate(-11.971deg) translateX(-50%) translateY(-16px);
            }
          }
        }

        &.two {
          left: 50%;
          transform: translateX(-50%) translateY(0px);

          animation: 0.25s ease-in-out 0.25s slightBounceMobile2;

          @keyframes slightBounceMobile2 {
            50% {
              left: 50%;
              transform: translateX(-50%) translateY(0px);
            }

            100% {
              left: 50%;
              transform: translateX(-50%) translateY(0px);
            }
          }
        }

        &.three {
          left: calc(50% + 80px);
          transform: rotate(12deg) translateX(-50%) translateY(20px);

          animation: 0.25s ease-in-out 0.25s slightBounceMobile3;

          @keyframes slightBounceMobile3 {
            50% {
              left: calc(50% + 90px);
              transform: rotate(12deg) translateX(-50%) translateY(20px);
            }

            100% {
              left: calc(50% + 80px);
              transform: rotate(12deg) translateX(-50%) translateY(20px);
            }
          }
        }

        &.four {
          left: calc(50% - 100px);
          transform: rotate(-12.975deg) translateX(-50%) translateY(130px);

          animation: 0.25s ease-in-out 0.25s slightBounceMobile4;

          @keyframes slightBounceMobile4 {
            50% {
              left: calc(50% - 110px);
              transform: rotate(-12.975deg) translateX(-50%) translateY(140px);
            }

            100% {
              left: calc(50% - 100px);
              transform: rotate(-12.975deg) translateX(-50%) translateY(130px);
            }
          }
        }

        &.five {
          left: calc(50% + 100px);
          transform: rotate(14.972deg) translateX(-50%) translateY(190px);

          animation: 0.25s ease-in-out 0.25s slightBounceMobile5;

          @keyframes slightBounceMobile5 {
            50% {
              left: calc(50% + 110px);
              transform: rotate(14.972deg) translateX(-50%) translateY(200px);
            }

            100% {
              left: calc(50% + 100px);
              transform: rotate(14.972deg) translateX(-50%) translateY(190px);
            }
          }
        }

        @media (min-width: 768px) {
          &.one {
            width: 272px;
            left: calc(50% - 233px);
            transform: rotate(-11.971deg) translateX(-50%) translateY(-16px);

            animation: 0.25s ease-in-out 0.25s slightBounceTablet1;

            @keyframes slightBounceTablet1 {
              50% {
                width: 272px;
                left: calc(50% - 243px);
                transform: rotate(-11.971deg) translateX(-50%) translateY(-16px);
              }

              100% {
                width: 272px;
                left: calc(50% - 233px);
                transform: rotate(-11.971deg) translateX(-50%) translateY(-16px);
              }
            }
          }

          &.two {
            left: calc(50% + 6px);
            transform: rotate(-0.038deg) translateX(-50%) translateY(-10px);

            animation: 0.25s ease-in-out 0.25s slightBounceTablet2;

            @keyframes slightBounceTablet2 {
              50% {
                left: calc(50% + 11px);
                transform: rotate(-0.038deg) translateX(-50%) translateY(-20px);
              }

              100% {
                left: calc(50% + 6px);
                transform: rotate(-0.038deg) translateX(-50%) translateY(-10px);
              }
            }
          }

          &.three {
            left: calc(50% + 234px);
            transform: rotate(12deg) translateX(-50%) translateY(42px);

            animation: 0.25s ease-in-out 0.25s slightBounceTablet3;

            @keyframes slightBounceTablet3 {
              50% {
                left: calc(50% + 244px);
                transform: rotate(12deg) translateX(-50%) translateY(42px);
              }

              100% {
                left: calc(50% + 234px);
                transform: rotate(12deg) translateX(-50%) translateY(42px);
              }
            }
          }

          &.four {
            left: calc(50% - 175px);
            transform: rotate(-12.975deg) translateX(-50%) translateY(258px);

            animation: 0.25s ease-in-out 0.25s slightBounceTablet4;

            @keyframes slightBounceTablet4 {
              50% {
                left: calc(50% - 185px);
                transform: rotate(-12.975deg) translateX(-50%) translateY(268px);
              }

              100% {
                left: calc(50% - 175px);
                transform: rotate(-12.975deg) translateX(-50%) translateY(258px);
              }
            }
          }

          &.five {
            left: calc(50% + 205px);
            transform: rotate(14.972deg) translateX(-50%) translateY(331px);

            animation: 0.25s ease-in-out 0.25s slightBounceTablet5;

            @keyframes slightBounceTablet5 {
              50% {
                left: calc(50% + 215px);
                transform: rotate(14.972deg) translateX(-50%) translateY(341px);
              }

              100% {
                left: calc(50% + 205px);
                transform: rotate(14.972deg) translateX(-50%) translateY(331px);
              }
            }
          }
        }

        @media (min-width: 1550px) {
          &.one {
            width: 354px;
            left: calc(50% - 533px);
            transform: rotate(-11.971deg) translateX(-50%) translateY(-40px);

            animation: 0.25s ease-in-out 0.25s slightBounceDT1;

            @keyframes slightBounceDT1 {
              50% {
                width: 354px;
                left: calc(50% - 553px);
                transform: rotate(-13.971deg) translateX(-50%) translateY(-45px);
              }

              100% {
                width: 354px;
                left: calc(50% - 533px);
                transform: rotate(-11.971deg) translateX(-50%) translateY(-40px);
              }
            }
          }

          &.two {
            left: calc(50% - 274px);
            transform: rotate(9.963deg) translateX(-50%) translateY(58px);
            animation: 0.25s ease-in-out 0.25s slightBounceDT2;

            @keyframes slightBounceDT2 {
              50% {
                left: calc(50% - 294px);
                transform: rotate(11.963deg) translateX(-50%) translateY(63px);
              }

              100% {
                left: calc(50% - 274px);
                transform: rotate(9.963deg) translateX(-50%) translateY(58px);
              }
            }
          }

          &.three {
            left: 50%;
            transform: translateX(-50%);

            animation: 0.25s ease-in-out 0.25s slightBounceDT3;

            @keyframes slightBounceDT3 {
              50% {
                left: 50%;
                transform: translateX(-50%);
              }

              100% {
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }

          &.four {
            left: calc(50% + 262px);
            transform: rotate(-12.975deg) translateX(-50%) translateY(-15px);
            animation: 0.25s ease-in-out 0.25s slightBounceDT4;

            @keyframes slightBounceDT4 {
              50% {
                left: calc(50% + 282px);
                transform: rotate(-14.975deg) translateX(-50%) translateY(-20px);
              }

              100% {
                left: calc(50% + 262px);
                transform: rotate(-12.975deg) translateX(-50%) translateY(-15px);
              }
            }
          }

          &.five {
            left: calc(50% + 556px);
            transform: rotate(14.972deg) translateX(-50%) translateY(107px);
            animation: 0.25s ease-in-out 0.25s slightBounceDT5;

            @keyframes slightBounceDT5 {
              50% {
                left: calc(50% + 576px);
                transform: rotate(16.972deg) translateX(-50%) translateY(112px);
              }

              100% {
                left: calc(50% + 556px);
                transform: rotate(14.972deg) translateX(-50%) translateY(107px);
              }
            }
          }
        }
      }
    }
  }

  div.why {
    padding-top: 251px;

    ul.cards {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 51px auto;

      li.card,
      li.card > div.exception {
        position: relative;
        width: 90%;
        max-width: 1220px;
        overflow-y: hidden;
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(5px);
        margin: 0 auto 40px;
        padding: 30px;
        align-items: center;
        gap: 30px;
        flex-direction: column;

        @media (min-width: 1024px) {
          height: auto;
          max-height: none;
          flex-direction: row;
          gap: 66px;
          padding: 70px 81px 51px 74px;
        }

        &:first-of-type {
          background: rgba(255, 255, 255, 1);
        }

        &:nth-of-type(2) {
          @media (min-width: 1193px) {
            height: 540px;
            max-height: 540px;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        &:nth-of-type(2) {
          > img {
            @media (min-width: 1024px) {
              align-self: flex-start;
            }
          }
        }

        p {
          font-family: 'Pretendard', sans-serif;
          color: #475569;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          &.title {
            font-family: 'Raleway', sans-serif;
            font-size: 30px;
            font-weight: 800;
            line-height: 40px;
            margin-bottom: 0;

            span {
              color: #0d9488;
            }
          }
        }

        > img,
        &.exception > img {
          width: 100%;
          max-width: 600px;

          @media (min-width: 1024px) {
            max-width: unset;
            width: 50%;
            height: auto;
          }

          @media (min-width: 1321px) {
            width: 510px;
            height: auto;
          }
        }

        > div:not(.pulsing-animation, .dashboard-graphic, .tools-graphic, .exception) {
          display: flex;
          flex-direction: column;
          gap: 37px;
          align-items: flex-start;
          justify-content: center;
          max-width: 600px;

          ul {
            gap: 19px 16px;

            @media (min-width: 400px) {
              gap: 19px 17px;
            }

            @media (min-width: 1400px) {
              gap: 16px;
            }

            @media (min-width: 1620px) {
              gap: 19px 17px;
            }

            li.icons {
              width: 50px;
              height: 50px;
              border-radius: 12px;
              border-top: 1px solid #f1f4f9;
              border-right: 1px solid #f1f4f9;
              background: rgba(255, 255, 255, 0.5);
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              @media (min-width: 1024px) {
                width: 60px;
                height: 60px;
              }

              @media (min-width: 1300px) {
                width: 80px;
                height: 80px;
              }

              &.longer {
                display: block;
                height: auto;
                background: transparent;
              }

              > img {
                width: 30px;
                height: 30px;

                @media (min-width: 1300px) {
                  width: 40px;
                  height: 40px;
                }

                &.got {
                  width: 168px;
                  height: 66px;

                  @media (min-width: 1300px) {
                    width: 224px;
                    height: 88px;
                  }
                }

                &.usps {
                  width: 24px;
                  height: 15px;

                  @media (min-width: 1300px) {
                    width: 48px;
                    height: 30px;
                  }
                }

                &.ups {
                  max-width: 58px;

                  @media (min-width: 1300px) {
                    width: 58px;
                    height: 58px;
                  }
                }

                &.dhl {
                  max-width: 54px;

                  @media (min-width: 1300px) {
                    width: 54px;
                    height: 54px;
                  }
                }

                &.fedex {
                  max-width: 58px;

                  @media (min-width: 1300px) {
                    width: 58px;
                    height: 58px;
                  }
                }
              }
            }
          }
        }

        > div.exception {
          max-height: unset !important;
          height: unset !important;
          width: 100%;
          overflow: visible;
          padding: 0;
          margin: 0;
          gap: 66px !important;
        }

        div.integrate-inner {
          ul {
            gap: 19px 16px;

            @media (min-width: 400px) {
              gap: 19px 17px;
            }

            @media (min-width: 1400px) {
              gap: 16px;
            }

            @media (min-width: 1620px) {
              gap: 19px 17px;
            }

            li.icons {
              width: 50px;
              height: 50px;
              border-radius: 12px;
              border-top: 1px solid #f1f4f9;
              border-right: 1px solid #f1f4f9;
              background: rgba(255, 255, 255, 0.5);
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              &.gray {
                border-top: 1px solid #e4efee;
                border-right: 1px solid #e4efee;
                background: rgba(228, 239, 238, 0.5);
              }

              @media (min-width: 1024px) {
                width: 60px;
                height: 60px;
              }

              @media (min-width: 1300px) {
                width: 80px;
                height: 80px;
              }

              &.coming-soon {
                position: relative;

                img.coming-soon-img {
                  display: block;
                  position: absolute;
                  top: 0;
                  right: 0;
                  transform: translate(50%, -50%);
                  width: 36px;
                  height: 36px;
                }
              }

              > img {
                width: 30px;
                height: 30px;

                @media (min-width: 1300px) {
                  width: 40px;
                  height: 40px;
                }

                &.shopify {
                  width: 45px;
                  height: 45px;

                  @media (min-width: 1300px) {
                    width: 55px;
                    height: 55px;
                  }
                }

                &.amazon {
                  width: 33.75px;
                  height: 10.625px;

                  @media (min-width: 1300px) {
                    width: 67.5px;
                    height: 21.25px;
                  }
                }

                &.ebay {
                  width: 24px;
                  height: 15px;

                  @media (min-width: 1300px) {
                    width: 70px;
                    height: 28.75px;
                  }
                }

                &.tiktok {
                  width: 30px;
                  height: 30px;

                  @media (min-width: 1300px) {
                    width: 40px;
                    height: 40px;
                  }
                }

                &.plus {
                  width: 60px;
                  height: 60px;

                  @media (min-width: 1300px) {
                    width: 80px;
                    height: 80px;
                  }
                }
              }
            }
          }
        }

        div.pulsing-animation {
          display: block;
          position: absolute;
          top: 15%;
          left: 85%;
          transform: translate(-50%, -50%);

          @media (min-width: 414px) {
            top: 20%;
          }

          @media (min-width: 768px) {
            top: 25%;
            left: calc(50% + 180px);
          }

          @media (min-width: 1024px) {
            top: 50%;
            left: calc(50% - 60px);
          }

          @media (min-width: 1080px) {
            top: 50%;
            left: calc(50% - 48px);
          }

          p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 5px;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            background: rgba(220, 238, 236, 0.4);
            backdrop-filter: blur(10px);
            padding: 10px 18px;
            color: #0d786c;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 1;
            letter-spacing: -0.66px;
            text-transform: capitalize;

            @media (min-width: 768px) {
              padding: 20px 36px;
              font-size: 30px;
              line-height: 45px;
              border-radius: 20px;
            }
          }

          div.ring {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            opacity: 0;
            animation: fadeInOut 1500ms infinite;

            &.one {
              border: 1px solid #4fb0a6;
              width: 82.533px;
              height: 82.533px;
              animation-delay: 0ms;
            }

            &.two {
              border: 1px solid #16baa8;
              width: 52px;
              height: 52px;
              animation-delay: 250ms;

              @media (min-width: 768px) {
                width: 105.782px;
                height: 105.782px;
              }
            }

            &.three {
              border: 1px solid #a6dbd6;
              width: 65px;
              height: 65px;
              animation-delay: 500ms;

              @media (min-width: 768px) {
                width: 131.355px;
                height: 131.355px;
              }
            }

            &.four {
              border: 1px solid #d9efec80;
              width: 80px;
              height: 80px;
              animation-delay: 750ms;

              @media (min-width: 768px) {
                width: 159.254px;
                height: 159.254px;
              }
            }

            &.five {
              border: 1px solid #d9efec33;
              width: 95px;
              height: 95px;
              animation-delay: 1000ms;

              @media (min-width: 768px) {
                width: 191.802px;
                height: 191.802px;
              }
            }

            &.six {
              border: 1px solid #d9efec1a;
              width: 115px;
              height: 115px;
              animation-delay: 1250ms;

              @media (min-width: 768px) {
                width: 229px;
                height: 229px;
              }
            }
          }

          @keyframes fadeInOut {
            0%,
            100% {
              opacity: 0;
            }

            50% {
              opacity: 1;
            }
          }
        }

        > div.dashboard-graphic {
          width: 100%;
          max-width: 600px;

          @media (min-width: 1024px) {
            width: 125%;
            max-width: 510px;
          }

          > div {
            img {
              height: auto;
              opacity: 0;
              transition: opacity 500ms linear;

              &:first-of-type {
                width: 31%;
                transition-delay: 0ms;
              }

              &:nth-of-type(2) {
                width: 27%;
                transition-delay: 500ms;
              }

              &:last-of-type {
                width: 42%;
                transition-delay: 1000ms;
              }
            }
          }

          > img {
            height: auto;
            opacity: 0;
            transition: opacity 500ms linear;
            transition-delay: 1500ms;
          }

          &.fade-in {
            > div {
              > img {
                opacity: 1;
              }
            }

            img {
              opacity: 1;
            }
          }
        }

        > div.tools-graphic {
          gap: 10px;
          width: 100%;
          max-width: 600px;

          @media (min-width: 1024px) {
            width: 125%;
            max-width: 510px;
            padding: 0 12px;
          }

          + div {
            gap: 25px !important;

            li.icons {
              width: 50px;
              height: 50px;

              @media (min-width: 1024px) {
                width: 64px !important;
                height: 64px !important;
              }

              > img {
                width: 30px;
                height: 30px;

                @media (min-width: 1024px) {
                  width: 32px !important;
                  height: 32px !important;
                }
              }
            }
          }

          img {
            height: auto;
            opacity: 0;
            transition: opacity 500ms linear;
          }

          > img {
            opacity: 0;
            transition: opacity 500ms linear;
            transition-delay: 1500ms;
            width: calc(50% - 5px);
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

            &:first-of-type {
              transition-delay: 0ms;
            }

            &:nth-of-type(2) {
              transition-delay: 500ms;
            }

            &:nth-of-type(3) {
              transition-delay: 1000ms;
            }

            &:last-of-type {
              transition-delay: 1500ms;
            }
          }

          &.fade-in {
            > div {
              > img {
                opacity: 1;
              }
            }

            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  section.whats-new {
    padding: 5rem 0;
    background: linear-gradient(97deg, #fff 0.31%, #e7e9ec 100%);

    @media (min-width: 1024px) {
      padding: 60px 0 85px;
    }

    > .container {
      display: flex;
      flex-direction: column;
      width: 90%;
      max-width: 1220px;

      @media (min-width: 1024px) {
        flex-direction: row;
        padding: 0;
      }

      h2.custom-h2 {
        color: #0d9488;

        &.bigger {
          text-align: center;

          @media (min-width: 1024px) {
            font-size: 62px;
            text-align: left;
          }

          @media (min-width: 1620px) {
            font-size: 80px;
            line-height: 100px;
          }
        }
      }

      .copy-container {
        @media (min-width: 768px) {
          min-width: 380px;
        }

        @media (min-width: 1024px) {
          min-width: 380px;
        }

        @media (min-width: 1620px) {
          min-width: 471px;
        }
      }
    }
  }

  img.shipbae-logo {
    width: 384px;
    height: 92px;
  }

  table.table-bordered.privacy-table {
    @media (max-width: 539px) {
      display: block;
      width: 100%;
      max-width: 100%;
      overflow-y: hidden;
      overflow-x: scroll;
      table-layout: fixed;

      td.fixed-col {
        background: #f0fdfa !important;
      }

      th,
      td {
        min-width: 200px;
        width: 200px;
      }

      .fixed-col {
        position: sticky;
        min-width: 150px;
        width: 150px;
        left: 0;
        z-index: 1;
        border-left: 0 !important;
        border-right: 0 !important;

        &:before,
        &:after {
          content: '';
          position: absolute;
          display: block;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: 1px;
          background: #181c32;
        }

        &:before {
          left: 0;
        }

        &:after {
          right: -2px;
        }
      }
    }

    th,
    td {
      border: 1px solid #181c32 !important;
    }

    tbody tr:last-child td {
      border-bottom: 1px solid #181c32 !important;
    }
  }
}

//
// Integration Pages
//
.amazon-integration,
.shipstation-integration,
.shopify-integration,
.walmart-integration,
.tiktok-integration,
.ebay-integration {
  margin-top: 69.75px;

  @media (min-width: 768px) {
    margin-top: 75.45px;
  }

  .gradient-bg {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) -9.09%, #bee5e2 94.94%);
  }

  .light-blue-bg {
    background: $light-blue;
  }

  .w-fit-content {
    width: fit-content;
  }

  div.hero {
    width: 90%;
    padding-top: 106px !important;
    padding-bottom: 28px !important;
    @media (min-width: 768px) {
      padding-top: 217px !important;
    }

    .logo-container {
      padding: 24px 53px;
      margin: 0 auto 100px;
      border-radius: 20px;
      border: 2px solid #14b8a6;
      background: #fff;

      @media (min-width: 768px) {
        padding: 48px 107px 47px;
        width: 570px;
        height: 208px;
      }

      img {
        display: block;
        max-width: 100%;
        width: 100%;

        @media (min-width: 768px) {
          width: 356px;
          height: 113px;
        }
      }
    }

    .integration-x-container {
      gap: 40px;
      margin: 0 auto 14px;

      img {
        display: block;

        &:first-of-type {
          width: 120px;
          height: auto;
        }

        &:nth-of-type(2) {
          width: 17.34px;
          height: auto;
        }

        &:last-of-type {
          width: 147.724px;
          height: auto;
        }
      }
    }

    h2 {
      color: #0d9488;
      font-family: 'Raleway', sans-serif;
      font-size: 42px;
      font-weight: 800;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 19px;
      @media (min-width: 768px) {
        font-size: 56px;
      }
    }

    p {
      color: #1e2a3b;
      font-family: 'Pretendard', sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
    }
  }

  div.what-we-offer {
    width: 90%;
    max-width: 1220px;
    padding-top: 54.5px !important;
    padding-bottom: 95px !important;

    @media (min-width: 768px) {
      padding-top: 109px !important;
      padding-bottom: 190px !important;
    }

    h2 {
      color: #f90;
      font-family: 'Raleway', sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-transform: capitalize;
    }

    ul.d-flex {
      gap: 80px;
      padding: 35px 0 0;
      @media (min-width: 768px) {
        padding: 35px 0;
      }

      li.d-flex {
        gap: 24px;

        img {
          max-width: 100%;

          @media (min-width: 768px) {
            flex: 1;
            width: 568px;
            height: auto;
            max-width: 50%;
          }
        }

        div {
          width: 100%;

          @media (min-width: 768px) {
            flex: 1;
            width: 50%;
            max-width: 50%;
            padding: 54px 60px 54px 36px;
          }

          p {
            color: #1e2a3b;
            font-family: 'Pretendard', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px; /* 160% */
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

//
// PricingPage Page
//
.pricing-page-container {
  margin-top: 160px;

  @media (min-width: 768px) {
    margin-top: 80.9px;
  }

  a.btn {
    &.btn-primary.hover-orange {
      &:hover {
        color: #fff !important;
        background-color: #ffa25f !important;
      }
    }
  }

  .hero.container {
    h2 {
      font-size: 40px;
      line-height: 1;
      font-weight: 800 !important;
    }

    p {
      color: #1e2a3b;
      font-size: 16px;
    }
  }

  .card {
    transition: box-shadow 0.25s linear;

    &.shadow-sm:hover {
      z-index: 1;
      box-shadow: 0 0.5rem 1rem 0.5rem rgba(0, 0, 0, 0.2) !important;
    }
    .card-header {
      &:first-child {
        border-radius: calc(0.85rem - 2px) calc(0.85rem - 2px) 0 0;
      }
    }
  }

  .pricing-faq.container {
    margin-bottom: 120px !important;

    h2 {
      color: #27364b;
      font-family: 'Raleway', sans-serif;
      font-size: 40px;
      font-weight: 800;
    }
  }
}

//
// Reused on Multiple Pages
//
section.what-is-shipbae {
  padding-top: 54.5px !important;
  padding-bottom: 61px !important;
  background: #f6f8fc;

  .container {
    width: 90%;
    max-width: 1220px;
  }

  h2 {
    color: #0d9488;
    font-family: 'Raleway', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
  }

  p {
    color: #1e2a3b;
    font-family: 'Pretendard', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
  }

  a.btn {
    font-family: 'Pretendard', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    text-transform: capitalize;
    padding: 1rem;

    @media (min-width: 1024px) {
      font-size: 24px;
      padding: 1.5rem;
    }

    &.btn-primary.hover-orange {
      &:hover {
        color: #fff !important;
        background-color: #ffa25f !important;
      }
    }
  }
}

section.how-it-works {
  padding: 5rem 0;

  @media (min-width: 1024px) {
    padding: 116px 0;
  }

  div.container {
    width: 90%;
    max-width: 1220px;

    h2 {
      color: #27364b;
      font-family: 'Raleway', sans-serif;
      font-size: 40px;
      font-weight: 800;
    }

    div.d-flex {
      gap: 24px;

      div.step {
        flex: 1;

        img {
          width: 72px;
          height: 72px;
          margin-bottom: 39px;
        }

        p {
          font-family: 'Pretendard', sans-serif;

          &.numbered {
            color: #000;
            font-feature-settings: 'liga' off, 'clig' off;
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            /* 150% */
            margin-bottom: 12px;
          }

          &.description {
            color: #1e2a3b;
            font-feature-settings: 'liga' off, 'clig' off;
            font-size: 20px;
            font-weight: 500;
            line-height: 36px;
            /* 180% */
          }
        }
      }
    }
  }
}

section.calculate-labels {
  padding-top: 54.5px !important;
  padding-bottom: 61px !important;
  background: #f6f8fc;

  .container {
    width: 90%;
    max-width: 1220px;
  }

  h2 {
    color: #0d9488;
    font-family: 'Raleway', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: capitalize;
  }

  p {
    color: #1e2a3b;
    font-family: 'Pretendard', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 160% */
  }

  a.btn {
    &.btn-primary.hover-orange {
      &:hover {
        color: #fff !important;
        background-color: #ffa25f !important;
      }
    }
  }
}

section.security {
  padding: 5rem 0;

  @media (min-width: 1024px) {
    padding: 125px 0;
  }

  div.container {
    width: 90%;
    max-width: 1220px;

    h2 {
      color: #0d9488;
      font-family: 'Raleway', sans-serif;
      font-size: 40px;
      font-weight: 800;
    }

    .row {
      border: 1px solid #14b8a6;
      border-radius: 20px;
    }

    .cell {
      &:hover {
        background: #f6f8fc;
      }

      @media (min-width: 768px) {
        min-height: 300px;
      }

      .card {
        background: transparent;
      }

      &:first-of-type {
        border-bottom: 1px solid #14b8a6;
        background: #e4efee;
        border-radius: 20px 20px 0 0;

        &:hover {
          background: #e4efee;
        }

        @media (min-width: 768px) {
          border-radius: 20px 0 0 0;
        }

        @media (min-width: 1200px) {
          padding-right: 70px !important;
        }

        @media (min-width: 1300px) {
          padding-right: 100px !important;
        }
      }

      &:nth-of-type(2) {
        border-bottom: 1px solid #14b8a6;

        @media (min-width: 768px) {
          border-left: 1px solid #14b8a6;
        }
      }

      &:nth-of-type(3) {
        border-bottom: 1px solid #14b8a6;

        @media (min-width: 768px) {
          border-left: 1px solid #14b8a6;
          border-radius: 0 20px 0 0;
        }
      }

      &:nth-of-type(4) {
        border-bottom: 1px solid #14b8a6;
      }

      &:nth-of-type(5) {
        border-bottom: 1px solid #14b8a6;

        @media (min-width: 768px) {
          border-left: 1px solid #14b8a6;
        }
      }

      &:nth-of-type(6) {
        border-bottom: 1px solid #14b8a6;

        @media (min-width: 768px) {
          border-left: 1px solid #14b8a6;
        }
      }

      &:nth-of-type(7) {
        border-bottom: 1px solid #14b8a6;

        @media (min-width: 768px) {
          border-bottom: 0;
          border-radius: 0 0 0 20px;
        }
      }

      &:nth-of-type(8) {
        border-bottom: 1px solid #14b8a6;

        @media (min-width: 768px) {
          border-bottom: 0;
          border-left: 1px solid #14b8a6;
        }
      }

      &:last-of-type {
        @media (min-width: 768px) {
          border-left: 1px solid #14b8a6;
          border-radius: 0 0 20px 0;
        }
      }
    }

    h3 {
      font-family: 'Raleway', sans-serif;
      color: #0d9488;
      font-size: 32px;
      line-height: 42px;
      font-weight: 800;
    }

    img,
    div.spacer {
      width: 64px;
      height: 64px;
    }

    h5 {
      color: #0f1a2a;
      font-family: 'Raleway', sans-serif;
      font-size: 20px;
      font-weight: 800;
      line-height: 32px;
      text-transform: capitalize;
    }

    .description {
      color: #94a3b8;
      font-family: 'Pretendard', sans-serif;
      font-size: 15px;
      line-height: 20px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 4;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}

section.enjoy {
  padding: 5rem 0;

  img.shipbae-logo {
    width: 384px;
    height: 92px;
  }

  a.btn {
    font-family: 'Pretendard', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    text-transform: capitalize;
    padding: 1rem;

    @media (min-width: 1024px) {
      font-size: 24px;
      padding: 1.5rem;
    }

    &.gradient {
      border-radius: 40px;
      backdrop-filter: blur(10px);
      background: linear-gradient(
          120deg,
          rgba(52, 191, 176, 0.8) 0%,
          rgba(151, 71, 255, 0.8) 33%,
          rgba(151, 71, 255, 0.8) 66%,
          rgba(52, 191, 176, 0.8) 100%
        )
        0 0 / 300% 100%;
      transition: background-position 0.25s;

      &:hover {
        background-position: 100% 0;
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 7rem 0;
  }
}
