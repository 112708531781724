//
// Layout Config
//

// Root font Sizes
$root-font-size: 13px; // Root font size for desktop mode
$root-font-size-lg: 13px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Page bg
$page-bg: if(isDarkMode(), #151521, #e4efee) !default;

// Content Spacing
$content-spacing: (
  // Padding for desktop mode
  desktop: 50px,
  // Padding for laptop mode
  laptop: 30px,
  // Padding for tablet and mobile modes
  tablet-and-mobile: 20px
) !default;

// Header
$header-config: (
  // Desktop Mode
  desktop:
    (
      // Default Mode
      default:
        (
          height: 80px,
        ),
      // Fixed Mode
      fixed:
        (
          height: 65px,
          z-index: 97,
          bg-color: $body-bg,
          box-shadow: if(isDarkMode(), none, 0px 10px 30px 0px rgba(82, 63, 105, 0.08)),
        )
    ),
  // Laptop
  laptop:
    (
      // Default Mode
      default:
        (
          height: 70px,
        ),
      // Fixed Mode
      fixed:
        (
          height: 60px,
          z-index: 97,
          bg-color: $body-bg,
          box-shadow: if(isDarkMode(), none, 0px 10px 30px 0px rgba(82, 63, 105, 0.08)),
        )
    ),
  // Tablet & Mobile Modes
  tablet-and-mobile:
    (
      // Default Mode
      default:
        (
          height: 60px,
        ),
      // Fixed Mode
      fixed:
        (
          z-index: 97,
          height: 55px,
          bg-color: $body-bg,
          box-shadow: if(isDarkMode(), none, 0px 10px 30px 0px rgba(82, 63, 105, 0.08)),
        )
    )
) !default;

// Aside
$aside-config: (
  z-index: 1040,
  z-index-tablet: 1021,
  menu-indention: 0.75rem,
  bg-color: $primary,
  bg-color-submenu: rgb(246, 248, 252, 0.9),
  //  Tablet & mobile modes < 1400px
  tablet-mobile-width: 250px,
  tablet-mobile-border-radius: 2rem,
  // Laptop and Desktop mode >= 1400px
  laptop-desktop-bg-color: if(isDarkMode(), #1e1e2d, $white),
  laptop-desktop-width: 70px,
  laptop-desktop-submenu-width: 200px,
  laptop-desktop-border-radius: 0.75rem,
) !default;

// Sidebar
$sidebar-config: (
  z-index: 101,
  width: 400px,
  bg-color: if(isDarkMode(), #1e1e2d, #192440),
  tabs: (
    link-bg-color: (
      default: if(isDarkMode(), darken(#1e1e2d, 4%), #0e172e),
      active: if(isDarkMode(), darken(#1e1e2d, 6%), #07122d),
    ),
    link-icon-color: (
      default: #5e6278,
      active: $white,
    ),
  ),
  dashed-border-color: if(isDarkMode(), $gray-200, #3e4a69),
  muted-color: if(isDarkMode(), $text-muted, #546182),
  muted-bg-color: if(isDarkMode(), darken(#1e1e2d, 2%), #0e172e),
) !default;
