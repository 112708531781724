.button-hold {
  --icon: #f6f8ff;
  --progress-border: #646b8c;
  --progress-active: #fff;
  --progress-success: #5c86ff;
  --tick-stroke: var(--progress-active);
  --shadow-y: 8px;
  --shadow-blur: 16px;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  min-width: 112px;
  max-height: 40px;
  padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
  border: 0;
  border-radius: 0.65rem;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-appearance: none;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 8px 16px rgba(0, 9, 61, 0.2) !important;
  transform: scale(var(--scale, 1)) translateZ(0);

  & > div {
    margin-left: -7px;
    margin-right: 4px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background: var(--progress-border);

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      left: 2px;
      top: 2px;
      z-index: 1;
      position: absolute;
      background: linear-gradient($gori-color-c, $gori-color-d);
      border-radius: inherit;
      transform: scale(var(--background-scale, 1));
      transition: transform 0.32s ease;
    }

    svg {
      display: block;

      &.icon,
      &.tick {
        position: absolute;
      }

      &.icon {
        width: 8px;
        height: 8px;
        left: 6px;
        top: 6px;
        fill: var(--icon);
        z-index: 1;
        transition: opacity 0.2s, transform 0.2s;
        opacity: var(--icon-opacity, 1);
        transform: translateY(var(--icon-y, 0)) scale(var(--icon-scale, 1));
      }

      &.confirm-progress,
      &.tick {
        fill: none;
      }

      &.confirm-progress {
        width: 20px;
        height: 20px;
        transform: rotate(-90deg) scale(var(--progress-scale, 1));
        transition: transform 0.5s ease;

        circle {
          stroke-dashoffset: 1;
          stroke-dasharray: var(--progress-array, 0) 52;
          stroke-width: 16;
          stroke: var(--progress-active);
          transition: stroke-dasharray var(--duration) linear;
        }
      }

      &.tick {
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--tick-stroke);
        transition: stroke 0.3s ease 0.7s;

        polyline {
          stroke-dasharray: 18 18 18;
          stroke-dashoffset: var(--tick-offset, 18);
          transition: stroke-dashoffset 0.4s ease 0.7s;
        }
      }
    }
  }

  &:focus,
  &:hover {
    &:not(.process) {
      --shadow-y: 8px;
      --shadow-blur: 16px;
    }
  }

  &:active {
    &:not(.success) {
      --scale: 0.96;
      --shadow-y: 4px;
      --shadow-blur: 8px;
    }
  }

  &.process,
  &.success {
    --progress-array: 52;
    --icon-y: -4px;
    --icon-scale: 0.6;
    --icon-opacity: 0;
  }

  &.success {
    --progress-border: none;
    --progress-scale: 0.11;
    --tick-stroke: var(--progress-success);
    --background-scale: 0;
    --tick-offset: 36;

    & > div {
      svg {
        &.confirm-progress {
          animation: tick 0.3s linear forwards 0.4s;
        }
      }
    }
  }
}

.button-hold.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.6;
}

.popover-img {
  width: 150px;
  height: 55px;
}

@keyframes tick {
  100% {
    transform: rotate(-90deg) translate(0, -5px) scale(var(--progress-scale));
  }
}
