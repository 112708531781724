@import 'variables.custom';
@import '../layout/variables';

.infinite-scroll-component__outerdiv {
  flex: 1 1 auto;
}

.display-none {
  display: none !important;
}

.mw-210px {
  max-width: 210px !important;
}

.vh-75 {
  max-height: 75vh !important;
}

.col-7-equal {
  flex: 0 0 auto;
  width: calc(100% / 7);
}

.m-center {
  margin: 0 auto !important;
}

.bg-image-gori {
  background-image: $gori-gradient !important;
}

.text-gori {
  background: $gori-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.spinner-custom {
  width: 80px;
  height: 80px;
}

.size-icon-check {
  font-size: 124px;
}

.switch-company .select2-container--open > .select2-dropdown--below {
  bottom: 46px !important;
}

.switch-company.cursor-no-drop .select2-container--default .select2-selection--single:hover {
  cursor: no-drop;
}

.pre-line {
  white-space: pre-line !important;
}

.spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.spin_reverse {
  animation: spin_reverse 1s infinite linear;
}

@keyframes spin_reverse {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.table-tabs {
  &__tab {
    display: inline-flex;
    background-color: $white;
    font-weight: 600;
    font-size: 10px;
    color: gray;
    border: 2px solid $light;
    padding: 10px;
    border-radius: 15px;
    margin: 6px 6px 6px 0;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 2px;

    @media (min-width: 686px) {
      padding: 15px 20px;
      border-radius: 15px 15px 0 0;
      margin: 0;
      font-size: 14px;
    }

    &:hover,
    &.active {
      border-bottom: 2px solid $white;
      color: $primary;
      background: $white;
      box-shadow: rgba(0, 0, 0, 0.16) 0 2px 8px;
      font-size: 11px;

      @media (min-width: 686px) {
        font-size: 14px;
      }
    }

    &__count {
      margin-left: 4px;
      padding: 0 8px;
      background-color: $light;
      border-radius: 4px;

      &.active {
        color: $white;
        background-color: $primary;
      }
    }

    &:hover > &__count {
      margin-left: 4px;
      padding: 0 8px;
      background-color: $primary;
      border-radius: 4px;
      color: $white;
    }
  }

  &.table-tabs {
    &.claims-tabs {
      .table-tabs__tab {
        @media (min-width: 686px) {
          font-size: 10px;
          padding: 10px;
          border-radius: 15px;
          margin: 6px 6px 6px 0;
        }

        @media (min-width: 880px) {
          font-size: 14px;
          padding: 15px 20px;
          border-radius: 15px 15px 0 0;
          margin: 0;
        }

        &:hover,
        &.active {
          @media (min-width: 686px) {
            font-size: 11px;
          }

          @media (min-width: 880px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.dropdown {
  min-width: 180px;

  &__label {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid $gray-300;
    border-radius: 10px;
    background-color: $white;
    white-space: nowrap;
    cursor: pointer;

    &__left {
      display: flex;
      align-items: center;
    }

    &__value {
      max-width: 150px;
      border-left: 1px solid #d1d1d1;
      padding-left: 10px;
      margin-left: 10px;

      &--input {
        max-width: 100px;
        outline: none;
        border: none;
        padding: 6px 8px;
        background: #f5f8fa;

        &:hover {
          background: #fff;
        }
      }
    }

    &__arrow {
      &:hover {
        color: hsl(0, 0%, 60%);
      }
    }
  }

  &__menu {
    background: white;
    position: absolute;
    right: 0;
    padding: 0;
    margin-top: 4px;
    border: 1px solid #d8dbdf;
    max-height: 380px;
    overflow-y: auto;
    max-width: 250px;

    .dropdown-submenu {
      &:first-child {
        border-radius: 0.65rem 0.65rem 0;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2px 0;
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
          color: $primary;
          background: $primary-active;
        }

        &.show {
          font-weight: 600;
          background-color: $light;
        }
      }

      .item-custom {
        color: black;
      }

      &__inner {
        display: none;

        &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 2px 0;
          padding: 8px 26px;
          cursor: pointer;

          &:hover {
            color: $primary;
            background: $primary-active;
          }
        }

        &.show {
          display: block;
        }

        .item-custom {
          color: black;

          &:hover {
            color: black;
          }
        }
      }
    }
  }
}

.gori-teal-btn {
  border-color: #00d3bc;
  background-color: #ffffff;
  color: #5e6278;

  &:hover {
    background-color: #00d3bc;
    color: $white;
  }
}

.status-user {
  &-Active {
    color: $user-status-active !important;
    background-color: $bg-user-status-active !important;
  }

  &-Inactive {
    color: $user-status-inactive !important;
    background-color: $bg-user-status-inactive !important;
  }
  &-Pending {
    color: $user-status-pending !important;
    background-color: $bg-user-status-pending !important;
  }
}

.input-split-quantity input {
  padding: 7px !important;
  text-align: center !important;
}

td:has(> .split-disabled),
tr td:has(> .split-disabled):hover {
  background: rgb(249, 250, 251) !important;
  cursor: not-allowed;
  opacity: 0.7;
}

.unpin {
  display: none;
}

.pin:hover {
  .unpin {
    display: block;
  }

  .pinned {
    display: none;
  }
}

.map-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #181c32;
  text-align: inherit;
  white-space: break-spaces;
  background-color: transparent;
  border: 0;
}

.map-item:hover {
  background-color: #ddd;
}

.secondary-text {
  color: rgb(123, 153, 168);
}

.hazmat {
  &__header {
    color: #3f4254;
    font-size: 22px;
    font-weight: 700;
    line-height: 150%; /* 33px */
    letter-spacing: -0.484px;
    text-transform: capitalize;
  }
  &__notice {
    color: #005595;
    font-size: 40px;
    font-weight: 700;
    line-height: 150%; /* 60px */
    letter-spacing: -0.88px;
  }
  &__blue {
    color: #005595;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: -0.44px;
  }

  &__title {
    font-size: 17px;

    &--bold {
      font-weight: 700;
    }

    &--semibold {
      font-weight: 600;
    }

    &--medium {
      font-weight: 500;
    }
  }

  &__content {
    color: #27364b;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 150%; /* 22.5px */
    letter-spacing: -0.33px;

    &--semibold {
      font-weight: 600;
    }

    &--medium {
      font-weight: 500;
    }

    &--regular {
      font-weight: 400;
    }
  }
  &__link {
    color: $primary;
    font-family: 'Pretendard', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-decoration-line: underline;
  }
  &__list {
    color: #27364b;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 150%; /* 22.5px */
    letter-spacing: -0.33px;

    strong {
      color: #27364b;
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      font-weight: 800;
      line-height: 150%; /* 27px */
      letter-spacing: -0.396px;
    }

    em {
      color: #27364b;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: -0.352px;
    }

    a {
      color: $primary;
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.33px;
    }
  }

  &__img {
    text-align: center;

    & > img {
      width: 100%;
      max-width: 100px;
      margin: 20px 15px;
    }
  }
  &__hr {
    background-color: #d9d9d9;
  }
}

.bg-darkTeal {
  background-color: #0cb9a8;
}

.text-darkTeal {
  color: #0cb9a8;
}

.custom__timeline {
  &__item {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin: 1rem 0;
  }

  &__label {
    position: relative;
    padding: 0 2rem;

    &:before {
      content: '';
      position: absolute;
      width: 2px;
      top: 0;
      bottom: 0;
      background-color: #b6b6c4;
    }
  }

  &__badge {
    background: #ffffff;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-left: -0.4rem;
    margin-top: 0.2rem;
  }

  &__content {
    margin-left: 0.5rem;
    display: flex;
  }
}

.shipment-dashboard-text {
  font-weight: bold;
  margin: 20px 0 35px 0;
  font-size: 14px;
}

.balance-button {
  padding: 5px !important;
  transition: all;
  border-radius: 12px;

  @media (min-width: 576px) {
    padding: 0 5px !important;
    border-radius: 50rem !important;
  }

  .balance-company {
    color: $gray-700;
    background: #e4efee;
    padding: 5px 10px;
    border-radius: 13px;
    margin: 2px 5px 2px 0;
    font-size: 14px;
    font-weight: normal;
  }

  .svg-icon.svg-icon-2 svg {
    margin-bottom: 2.5px;
  }

  &:hover {
    .balance-company {
      background-color: #00d3bc;
      color: $white;
    }

    .svg-icon.svg-icon-2 svg {
      color: $white;
    }
  }
}

.select-customs {
  position: relative;

  .select-customs {
    position: relative;

    &__icon-delete {
      position: absolute;
      top: 25%;
      right: 10%;
      padding: 0 4px;
    }
  }
}

.ps-img-preview {
  max-width: 250px;
}

.crop-image {
  min-height: 300px;
  width: 80%;
  margin: 10px auto;
}

.setting-block {
  width: 60%;
  margin: 0 auto;
  border: 1px solid #eff2f5;
  border-radius: 10px;
  padding: 20px;

  .setting-block-header {
    h3 {
      margin-bottom: 15px;
    }

    .text-setting-right {
      font-weight: 500;
      cursor: pointer;
      display: inline-block;
    }

    .text-modal {
      color: $primary;
      margin-left: 10px;
    }

    p {
      font-size: 12px !important;
    }
  }

  .setting-block-body {
    margin-top: 15px;

    table.table-profile {
      td {
        margin-bottom: 0.5rem;
        font-size: 1.05rem;
        font-weight: 500;
        color: #9497a3;
        width: 350px;
        padding: 10px 0;
      }

      td:last-child {
        color: #181c32 !important;
        width: auto !important;
      }
    }
  }
}

.change-password-block {
  width: 70%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .setting-block {
    width: 90%;
  }

  .change-password-block {
    width: 100%;
    margin: 0 auto;
  }

  .fixed-action {
    right: 39px;
  }

  .position-right-31px {
    right: 31px !important;
  }
}

.input-custom {
  position: relative;
  font-size: 1.25rem;

  .input-custom__input.is-invalid + .input-custom__append--right,
  .input-custom__input.is-valid + .input-custom__append--right {
    right: 40px;
  }

  &__append {
    color: $gray-600;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--right {
      right: 10px;
    }

    &--left {
      left: 10px;
    }
  }
}

#gori_adjustment_upload_modal {
  .timeline-item {
    margin-bottom: 0;
  }
}

span.signature {
  font-family: 'Mrs Saint Delafield', cursive;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  margin: auto;
  vertical-align: middle;
  word-break: break-word;
}

.batch-detail-menu {
  margin-top: -15px;

  .svg-icon svg {
    width: 12px;

    path {
      fill: $primary !important;
    }
  }
}

.fixed-column {
  @media (min-width: 768px) {
    z-index: 0 !important;
    position: sticky;
    right: 0;
    background-color: $white !important;
  }
}

.fixed-action {
  @media (min-width: 768px) {
    z-index: 0 !important;
    position: sticky;
    right: 39px;
    background-color: $white !important;
  }
}

.dropdown-menu.show {
  @at-root .fixed-column:has(.dropdown-menu.show) {
    z-index: 1 !important;
  }
}

.bg-input-dark,
.bg-input-dark .dropdown__label.btn.btn-sm.btn-white.btn-active-light-primary.border,
.bg-input-dark .css-1pfrc41-control,
.bg-input-dark .css-1ftwjqv-control {
  background-color: #f6f8fc !important;
}

.bg-input-dark .dropdown__label__value--input,
.bg-input-dark .dropdown__label__value {
  background-color: #eff2f5 !important;
}

.hazmat_content {
  margin: 0 auto 10rem;
  text-align: justify;
}

.btn-none {
  outline: none !important;
  transition: all !important;
  box-shadow: none !important;
}

.ship_from_address {
  border-radius: 15px;
  padding: 20px 10px;
  background-color: #f4f6fa;
  margin-bottom: 30px;
}

.ship_from_address_profile {
  border-radius: 15px;
  padding: 10px;
  background-color: #f4f6fa;
}

span.mh-80px > svg.mh-50px {
  max-height: 80px !important;
}

.settings-container {
  height: 100%;

  @media (min-width: 1400px) {
    padding-left: 170px;
  }
}

.footer.settings-padding {
  @media (min-width: 1400px) {
    padding-left: 170px;
  }
}

#gori_aside.solid {
  + .aside--sub {
    @media (min-width: 1400px) {
      background-color: rgba(246, 248, 252, 1) !important;
    }
  }
}

#gori_aside_footer {
  .mobile-only {
    @media (min-width: 1400px) {
      display: none !important;
    }
  }

  .desktop-only {
    display: none !important;

    @media (min-width: 1400px) {
      display: block !important;
    }
  }
}

.bg-page {
  background-color: $page-bg !important;
}

ol.paren-list-num {
  list-style-type: none;
  counter-reset: list;

  li {
    counter-increment: list;
    position: relative;
    padding-left: 2.5em;

    &:before {
      content: '( ' counter(list) ' )';
      position: absolute;
      left: 0;
    }
  }
}

ol.paren-list-alpha {
  list-style-type: none;
  counter-reset: list;

  li {
    counter-increment: list;
    position: relative;
    padding-left: 2.5em;

    &:before {
      content: '( ' counter(list, lower-alpha) ' )';
      position: absolute;
      left: 0;
    }
  }
}

// Store Connections Page Start
@media only screen and (min-width: 1400px) and (max-width: 1550px) {
  div.row.fix-1400 {
    margin: 0 !important;
  }
}

@media only screen and (min-width: 3000px) {
  .store-connections-cards {
    > div {
      min-width: 450px;
      max-width: 450px;

      > div {
        min-height: 435px;
        max-height: 435px;

        img {
          width: 210px;
          height: 210px;
        }

        .btn {
          font-size: 2rem !important;
        }

        p.btn {
          line-height: 33.8px;
        }
      }
    }
  }
}

.bg-order-detail-report {
  background-color: #fffaee;
}
// Store Connections Page End

// Timeline Shipment Tracking Info Start
.timeline.tracking-detail {
  position: relative;
  border-left: 4px solid $shipment-tracking;
}

.timeline-marker {
  width: 12px;
  height: 12px;
  background-color: $shipment-tracking;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: -8px;
}

.text-purple {
  color: $shipment-tracking;
}
// Timeline Shipment Tracking Info End
