.tooltip-modal {
  position: relative;

  &__text {
    visibility: hidden;
    width: 120px;
    background-color: #ffffff;
    color: #7e8299;
    border-radius: get($menu, dropdown, border-radius);
    background-color: get($menu, dropdown, background-color);
    box-shadow: get($menu, dropdown, box-shadow);
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    cursor: pointer;
    z-index: 1;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;

    &--top {
      bottom: 100%;
    }
    &--bottom {
      top: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
    }
    &--top::after {
      top: 100%;
      border-color: #ffffff transparent transparent transparent;
    }
    &--bottom::after {
      bottom: 100%;
      border-color: transparent transparent #ffffff transparent;
    }
  }

  &:hover &__text {
    visibility: visible;
    opacity: 1;
  }
}
