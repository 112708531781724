@import 'variables.custom';
@import '../core/components/variables';

.daterangepicker {
  box-shadow: $dropdown-box-shadow;
  padding: $input-btn-padding-y-sm $input-btn-padding-x-sm !important;
  margin: 4px 0 0 0 !important;
  border: 0;
  width: auto;
  background-color: #f6f8fc !important;
  font-family: $font-family-sans-serif;
  z-index: 1000;
}

.daterangepicker .ranges {
  border-radius: $border-radius;
  background-color: #f6f8fc !important;
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker .ranges ul {
  padding: 1rem 0;
  list-style: none;
  margin: 0 auto;
  width: 100%;
}

.daterangepicker .ranges li {
  padding: 0.7rem 1.75rem;
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  color: $gray-600;
  transition: color 0.2s ease, background-color 0.2s ease;
  cursor: pointer;
  border-radius: $border-radius-sm !important;

  &:hover {
    background-color: $primary-light;
    color: $primary;
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  &.active {
    color: $primary-light !important;
    background-color: $primary !important;
    transition: $transition-link;
  }
}

.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: none;
}

.daterangepicker-label {
  border-radius: $border-radius !important;
  font-weight: $font-weight-bolder !important;
  cursor: pointer !important;
  width: 100% !important;
  padding: 1em 0.75rem !important;

  span {
    white-space: nowrap !important;
  }
}

@media (min-width: 564px) {
  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar,
  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    float: auto;
  }
}
.MuiFormControl-root {
  background-color: white;
  width: 100% !important;
}

.MuiOutlinedInput-root {
  font-size: 1.1rem !important;
  border-radius: 8.45px !important;
  font-weight: 600 !important;
  color: #5e6278 !important;
  font-family: $font-family-sans-serif !important;
}

.MuiOutlinedInput-input {
  padding: 9.75px 13px !important;
}

.required:after {
  content: '*';
  position: relative;
  font-size: inherit;
  color: #f1416c;
  padding-left: 0.25rem;
  font-weight: bold;
}
