.closed-date {
  font-size: 13px;
  width: 110px;
}
.min-height-50px {
  min-height: 50px;
}

.pdx-company {
  padding: 5px 10px;
  margin-bottom: 0;

  &:hover {
    background-color: $primary-light;
  }
}
