.drop-zone {
  max-height: 111px;
  overflow-y: scroll;

  .preview {
    display: flex;
    align-items: center;
    font-weight: 500;
    background-color: #dae6ff;
    padding: 8px;
    border-radius: 8px;

    &__left {
      display: flex;
      align-items: center;
      background-color: #dae6ff;
      font-weight: 500;

      & > img {
        border-radius: 4px;
        border: 1px solid #b5c2db;
        height: 26px;
        width: 26px;
        margin-right: 8px;
      }

      &__name {
        width: 220px;
      }
    }
  }

  .preview__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 40px;
    background-color: #dae6ff;
    border-radius: 8px;
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      background-color: #f45557;
    }
  }
}
