//
// Menu Theme
//

// Theme text colors
@each $name, $color in $theme-text-colors {
  .menu-#{$name} {
    .menu-item {
      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state($color, $color, $color, $color, null);
    }
  }

  .menu-title-#{$name} {
    .menu-item {
      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state($color, null, null, null, null);
    }
  }

  .menu-icon-#{$name} {
    .menu-item {
      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state(null, $color, null, null, null);
    }
  }

  .menu-bullet-#{$name} {
    .menu-item {
      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state(null, null, $color, null, null);
    }
  }

  .menu-arrow-#{$name} {
    .menu-item {
      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state(null, null, null, $color, null);
    }
  }
}

// Default background states
.menu-hover-bg {
  .menu-item {
    @include menu-link-hover-state(null, null, null, null, get($menu, link, self, bg-color, hover));
  }
}

.menu-here-bg {
  .menu-item {
    @include menu-link-here-state(null, null, null, null, get($menu, link, self, bg-color, here));
  }
}

.menu-show-bg {
  .menu-item {
    @include menu-link-show-state(null, null, null, null, get($menu, link, self, bg-color, show));
  }
}

.menu-active-bg {
  .menu-item {
    @include menu-link-active-state(
      null,
      null,
      null,
      null,
      get($menu, link, self, bg-color, active)
    );
  }
}

.menu-state-bg {
  .menu-item {
    @include menu-link-hover-state(null, null, null, null, get($menu, link, self, bg-color, hover));
    @include menu-link-here-state(null, null, null, null, get($menu, link, self, bg-color, here));
    @include menu-link-show-state(null, null, null, null, get($menu, link, self, bg-color, show));
    @include menu-link-active-state(
      null,
      null,
      null,
      null,
      get($menu, link, self, bg-color, active)
    );
  }
}

// Primary background states
.menu-hover-bg-primary {
  .menu-item {
    @include menu-link-hover-state(
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary
    );
  }
}

.menu-show-bg-primary {
  .menu-item {
    @include menu-link-show-state(
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary
    );
  }
}

.menu-here-bg-primary {
  .menu-item {
    @include menu-link-here-state(
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary
    );
  }
}

.menu-active-bg-primary {
  .menu-item {
    @include menu-link-active-state(
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary
    );
  }
}

.menu-state-bg-primary {
  .menu-item {
    @include menu-link-hover-state(
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary
    );
    @include menu-link-show-state(
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary
    );
    @include menu-link-here-state(
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary
    );
    @include menu-link-active-state(
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary-inverse,
      $primary
    );
  }
}

// Light primary background states
.menu-show-bg-light-primary {
  .menu-item {
    @include menu-link-show-state($primary, $primary, $primary, $primary, $primary-light);
  }
}

.menu-here-bg-light-primary {
  .menu-item {
    @include menu-link-here-state($primary, $primary, $primary, $primary, $primary-light);
  }
}

.menu-hover-bg-light-primary {
  .menu-item {
    @include menu-link-hover-state($primary, $primary, $primary, $primary, $primary-light);
  }
}

.menu-active-bg-light-primary {
  .menu-item {
    @include menu-link-active-state($primary, $primary, $primary, $primary, $primary-light);
  }
}

.menu-state-bg-light-primary {
  .menu-item {
    @include menu-link-show-state($primary, $primary, $primary, $primary, $primary-light);
    @include menu-link-here-state($primary, $primary, $primary, $primary, $primary-light);
    @include menu-link-hover-state($primary, $primary, $primary, $primary, $primary-light);
    @include menu-link-active-state($primary, $primary, $primary, $primary, $primary-light);
  }
}

// Primary color states
.menu-hover-primary {
  .menu-item {
    @include menu-link-hover-state($primary, $primary, $primary, $primary, null);
  }
}

.menu-show-primary {
  .menu-item {
    @include menu-link-show-state($primary, $primary, $primary, $primary, null);
  }
}

.menu-here-primary {
  .menu-item {
    @include menu-link-here-state($primary, $primary, $primary, $primary, null);
  }
}

.menu-active-primary {
  .menu-item {
    @include menu-link-active-state($primary, $primary, $primary, $primary, null);
  }
}

.menu-state-primary {
  .menu-item {
    @include menu-link-hover-state($primary, $primary, $primary, $primary, null);
    @include menu-link-show-state($primary, $primary, $primary, $primary, null);
    @include menu-link-here-state($primary, $primary, $primary, $primary, null);
    @include menu-link-active-state($primary, $primary, $primary, $primary, null);
  }
}

// Primary title color states
.menu-hover-title-primary {
  .menu-item {
    @include menu-link-hover-state($primary, null, null, null, null);
  }
}

.menu-here-title-primary {
  .menu-item {
    @include menu-link-here-state($primary, null, null, null, null);
  }
}

.menu-show-title-primary {
  .menu-item {
    @include menu-link-show-state($primary, null, null, null, null);
  }
}

.menu-active-title-primary {
  .menu-item {
    @include menu-link-active-state($primary, null, null, null, null);
  }
}

.menu-state-title-primary {
  .menu-item {
    @include menu-link-hover-state($primary, null, null, null, null);
    @include menu-link-show-state($primary, null, null, null, null);
    @include menu-link-active-state($primary, null, null, null, null);
  }
}

// Primary icon color states
.menu-hover-icon-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-hover-state(null, $primary, null, null, null);
  }
}

.menu-here-icon-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-here-state(null, $primary, null, null, null);
  }
}

.menu-show-icon-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-show-state(null, $primary, null, null, null);
  }
}

.menu-active-icon-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-active-state(null, $primary, null, null, null);
  }
}

.menu-state-icon-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-hover-state(null, $primary, null, null, null);
    @include menu-link-show-state(null, $primary, null, null, null);
    @include menu-link-active-state(null, $primary, null, null, null);
  }
}

// Primary bullet color states
.menu-hover-bullet-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-hover-state(null, null, $primary, null, null);
  }
}

.menu-show-bullet-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-show-state(null, null, $primary, null, null);
  }
}

.menu-here-bullet-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-here-state(null, null, $primary, null, null);
  }
}

.menu-active-bullet-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-active-state(null, null, $primary, null, null);
  }
}

.menu-state-bullet-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-hover-state(null, null, $primary, null, null);
    @include menu-link-show-state(null, null, $primary, null, null);
    @include menu-link-active-state(null, null, $primary, null, null);
  }
}

// Primary arrow color states
.menu-hover-arrow-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-hover-state(null, null, null, $primary, null);
  }
}

.menu-here-arrow-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-here-state(null, null, null, $primary, null);
  }
}

.menu-show-arrow-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-show-state(null, null, null, $primary, null);
  }
}

.menu-active-arrow-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-active-state(null, null, null, $primary, null);
  }
}

.menu-state-arrow-primary {
  .menu-item {
    //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
    @include menu-link-hover-state(null, null, null, $primary, null);
    @include menu-link-show-state(null, null, null, $primary, null);
    @include menu-link-active-state(null, null, null, $primary, null);
  }
}
