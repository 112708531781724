//
// Main
//

body {
  background-color: $page-bg;
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

// Laptop and Desktop mode >= 1400px
@include media-breakpoint-up(xxl) {
  .wrapper {
    // Sidebar enabled mode
    .sidebar-enabled & {
      padding-right: get($sidebar-config, width);
    }
  }

  // Engage toolbar
  .sidebar-enabled .engage-toolbar {
    right: get($sidebar-config, width) !important;
  }

  // Engage toolbar
  .sidebar-enabled .scrolltop {
    right: get($sidebar-config, width) + 5px !important;
  }
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    padding: 0 get($content-spacing, laptop) !important;
  }

  // Wrapper
  .wrapper {
    padding-top: get($content-spacing, laptop);
    padding-left: get($aside-config, laptop-desktop-width) + get($content-spacing, laptop);

    // Fixed header mode
    .header-fixed[data-kt-sticky-header='on'] & {
      padding-top: get($content-spacing, laptop) + get($header-config, laptop, default, height);
    }
  }
}

// // Laptop modes 1919px ~ 1400px
// @include media-breakpoint-between(xxl, 3xl) {
//   // Containers
//   .container,
//   .container-xxl,
//   .container-fluid {
//     padding: 0 get($content-spacing, laptop) !important;
//   }

//   // Wrapper
//   .wrapper {
//     padding-top: get($content-spacing, laptop);
//     padding-left: get($aside-config, laptop-width) + get($content-spacing, laptop);

//     // Fixed header mode
//     .header-fixed[data-kt-sticky-header='on'] & {
//       padding-top: get($content-spacing, laptop) + get($header-config, laptop, default, height);
//     }
//   }
// }

// Tablet & mobile modes < 1400px
@include media-breakpoint-down(xxl) {
  // Containers
  .container,
  .container-xxl,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile) !important;
  }

  // Wrapper
  .wrapper {
    // Fixed header mode
    .header-tablet-and-mobile-fixed[data-kt-sticky-header='on'] & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }
  }
}
