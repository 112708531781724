@import 'variables.custom';
@import '../core/components/variables';

.select2.select2-container.select2-container--default {
  .selection {
    .select2-selection.select2-selection--single {
      background-color: $gray-100 !important;
      border: none !important;
      padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
      font-size: $input-btn-font-size-lg !important;
      font-weight: $font-weight-bold !important;
      border-radius: $border-radius !important;
      height: auto;

      .select2-selection__rendered {
        .icon-delete {
          display: none;
        }
        .select2-selection__choice {
          background-color: $primary !important;
          color: white;
          border: none !important;
          padding: $input-btn-padding-y-sm $input-btn-padding-x-sm !important;
          font-size: $input-btn-font-size-lg !important;

          .select2-selection__choice__remove {
            color: white !important;
          }
        }

        .select2-selection__placeholder {
          color: $gray-500 !important;
        }
      }

      .select2-selection__arrow {
        height: 39px;
        position: absolute;
        top: 1px;
        right: 1px;
        width: 32px;
      }
    }
  }

  .select2-container.select2-container--default.select2-container-dropdown {
    .select2-dropdown.select2-dropdown--below {
      border: none;
      border-radius: 0.65rem;
      box-shadow: $dropdown-box-shadow;
      padding: $input-btn-padding-y-sm $input-btn-padding-x-sm !important;

      .select2-search.select2-search--dropdown {
        .select2-search__field {
          outline: none;
          border-radius: $border-radius-sm !important;
          border: 1px solid $gray-300;
        }
      }

      .select2-results {
        .select2-results__options {
          .select2-results__option {
            border-radius: $border-radius-sm !important;
            margin-top: 0.5rem;
            font-size: $font-size-base !important;
            font-weight: $font-weight-normal !important;
          }

          .select2-results__option.select2-results__option--highlighted {
            background-color: #f1faff;
            color: #009ef7;
            transition: color 0.2s ease, background-color 0.2s ease;
          }

          .select2-results__option[aria-selected='true'] {
            background-repeat: no-repeat;
            background-position: center;
            background-color: transparent;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='%23FFFFFF' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
            background-size: 0.8rem;
            background-position: center right 0.5rem;
            background-color: #009ef7;
            color: #fff;
            transition: color 0.2s ease, background-color 0.2s ease;
          }
          .select2-results__option .icon-remove {
            display: none;
          }
        }
      }
    }
  }
}

.form-select-normal {
  .select2.select2-container.select2-container--default {
    .selection {
      .select2-selection.select2-selection--single {
        background-color: $white !important;
        border: 0.5px solid #e4e6ef !important;
      }
    }
  }
}

.subdomain .css-1dml52s-control {
  background-color: $white !important;
}
