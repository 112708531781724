@import 'variables.custom';

.bulk-upload-usps {
  padding: 20px;
  background-color: #f6f8fc;
  border: 1px solid #dbe1ea;
}

.bulk-upload-usps table {
  overflow: hidden;
}

.bulk-upload-usps-first-child {
  background-color: #475669 !important;
  color: white !important;
  border: 1px solid #8a96a6 !important;
  text-align: center !important;
}

.bulk-upload-usps-td-first-child {
  background-color: #475669 !important;
  color: white !important;
  border: 1px solid #8a96a6 !important;
  padding: 8px 0px !important;
  text-align: center !important;
}

.bulk-upload-usps-td {
  border: 1px solid #475669 !important;
  background-color: white !important;
}
