@import 'variables.custom';
@import '../core/components/variables';

html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open) {
  overflow-y: inherit !important;

  ::-webkit-scrollbar {
    color: transparent !important;
    background-color: #999999 !important;
  }
}

div.swal2-popup {
  padding: 73.46px 0 0;

  .swal2-title {
    padding: 1.75rem 1.75rem;
  }

  .swal2-html-container {
    padding: 0 1.75rem;
  }

  .swal2-close {
    position: absolute;
    top: 1.75rem;
    right: 1.75rem;
    font-size: inherit;
    padding: 0.875rem 0.875rem;
    margin: -0.875rem -0.875rem -0.875rem auto;
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat !important;

    &:hover {
      color: #000000;
    }
  }

  .swal2-actions {
    border-top: 1px solid #eff2f5;
    width: 100%;
    justify-content: flex-end;
    padding: 1.5rem 1.75rem;
    margin: 0;

    button.btn {
      margin-top: 0;
      margin-bottom: 0;
      min-width: 67px;
    }
  }
}

.modal-content .min-w-67px {
  min-width: 67px !important;
}
